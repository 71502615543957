import React, { useEffect, useState } from 'react';
import { Button, Col, Space, Row, Image, List, Typography, Checkbox, Modal } from 'antd';
import img1 from '../../assets/images/img_1.jpg';
import img2 from '../../assets/images/img_2.jpg';
import img3 from '../../assets/images/img_3.jpeg';
import img4 from '../../assets/images/img_4.jpeg';
import img5 from '../../assets/images/img_5.jpeg';
import img6 from '../../assets/images/img_6.jpeg';
import img12 from '../../assets/images/img_12.jpg';
import yasin from '../../assets/alqurans/yasin.json';
import '../../assets/styles/font-arab.css';
import '../../assets/styles/font-header.css';



const { Text } = Typography;
function Home() {


  const isInstalled = window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone || // Untuk iOS
    window.matchMedia('(display-mode: fullscreen)').matches;

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault(); // Prevent browser default behavior
      setDeferredPrompt(event); // Store the event for later use
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // console.log(isInstalled);
    setIsModalOpen(!isInstalled);
    // Cleanup function
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isInstalled]);

  const handleInstallPWA = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null); // Reset the deferredPrompt state
      });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    handleInstallPWA();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [latine, setLatine] = useState(true);
  const [arab, setArab] = useState(true);

  const onLatine = async (e) => {
    console.log("latine: " + e.target.checked)
    setLatine(e.target.checked);
  };

  const onArab = async (e) => {
    setArab(e.target.checked);
  };

  return (
    <div className="container" style={{ padding: '16px' }}>
      <Modal title="Anda dapat menginstall applikasi website ini"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
            <Button type="primary" onClick={handleOk}>Install</Button>
          </>
        )}>
        <div className='popup'>
          <p>Kelebihan menginstall : </p>
          <p> - Lebih mudah untuk membuka aplikasi</p>
          <p> - Aplikasi lebih cepat dan responsif</p>
          <p> - Dapat digunakan tanpa internet</p>
        </div>
      </Modal>
      <div className="fhead">
        <p className="head">Mengenang Istri/Ibunda Kami Tercinta dan Tersayang</p>
        <div className="head2">
          <p>SISZY AMELIA</p>
          <p>Binti</p>
          <p>Mayangkoro</p>
        </div>



        <Row gutter={[16, 16]} align="middle" justify="center">
          <Col className="gutter-row" xs={0} sm={0} md={6} lg={6} >
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} >
            <Row gutter={[16, 16]} align="middle" justify="center">
              <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} >
                <p className="head3">Lahir :  Surabaya, 14 Mei 1967</p>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} >
                <p className="head3">Wafat : Jakarta, 3 Maret 2024</p>
              </Col>
            </Row>
            <Image src={img1} style={{ padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
          </Col>
          <Col className="gutter-row" xs={0} sm={0} md={6} lg={6} >
          </Col>
        </Row>
        <div className="head4">
          <p>Suara, senyum dan belaianmu tetap Kami rasakan. Nasehat, ajaran dan sifatmu yang lembut dan sabar dalam mendidik Kami, tidak akan pernah tertinggal dalam langkah Kami. Engkau Istri/Ibunda yang terhebat yang diberikan Allah kepada Kami, dan Kami sangat bersyukur karenanya. Kami akan selalu menyayangimu, Kami akan selalu mengingatmu selamanya. Tenanglah dalam keabadian, damailah dalam dekapan Allah SWT. Doa Kami akan selalu menyertaimu. Aamiin…</p>
        </div>
        <div className="head5">
          <p style={{ marginBottom: "0.7em" }}>Yang selalu dan sangat mencintaimu</p>
          <p>Suami</p>
          <p style={{ marginBottom: "0.7em" }}>Donny Andrianto</p>
          <p>Anak</p>
          <p>Bella Ananda</p>
          <p>Vella Ananda</p>
        </div>

      </div>
      <Row gutter={[16, 16]} align="middle" justify="center">

        <Col className="gutter-row" xs={12} sm={12} md={6} lg={6} >
          <Image src={img3} style={{ marginBottom: 16, padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
          <Image src={img4} style={{ padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
        </Col>
        <Col className="gutter-row" xs={12} sm={12} md={6} lg={6} >
          <Image src={img5} style={{ marginBottom: 16, padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
          <Image src={img6} style={{ padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} >
          <Image src={img2} style={{ padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} >
          <Image src={img12} style={{ padding: 10, border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: 'white' }} />
        </Col>

        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} >
          <List
            className='yasin'
            header={
              <Row align="middle" justify="center">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h3 style={{ margin: 0 }}>Surat Yasin</h3>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div style={{ textAlign: 'right' }}>
                    <Checkbox onChange={onLatine} checked={latine}>Latine</Checkbox>
                    <Checkbox onChange={onArab} checked={arab}>Arabic</Checkbox>
                  </div>
                </Col>
              </Row>
            }
            bordered
            itemLayout="horizontal"
            dataSource={yasin.verse}
            renderItem={(item) => (
              <List.Item>
                <Space align="center" size="large" style={{ width: '100%' }}>
                  {item.verse_number > 0 &&
                    <div className='number'>
                      <h5 style={{ margin: 0 }} >{item.verse_number > 0 ? item.verse_number : " "}</h5>
                    </div>
                  }
                  <div width={'100%'}>
                    {arab &&
                      <p className='arab' style={{ fontFamily: 'font-arab, sans-serif', textAlign: 'right', width: '100%' }}>{item.verse_text}</p>
                    }
                    {latine &&
                      <Text style={{ margin: 0 }}>{item.verse_latine}</Text>
                    }
                  </div>
                </Space>
              </List.Item>
            )}
          />
        </Col>
      </Row>


    </div>
  );

};

export default Home;